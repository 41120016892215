<template>
    <footer class="bg-gradient-to-b from-green-900 to-green-700 text-white p-12 flex flex-col gap-3">
  <div class="flex flex-col md:flex-row justify-evenly">
    <div class="md:text-left text-center mb-6 md:mb-0">
      <h1 class="text-2xl font-bold mb-2">WhatSell</h1>
      <p class="mb-2 text-sm md:max-w-[400px]">Go-to platform for instant local shopping! Browse nearby products and get them in minutes, list and sell products with ease.  <br/><strong>Shop with Steeze!!</strong></p>
      <div class="flex justify-center md:justify-start space-x-4">
        <!-- Social Media Icons -->
        <a href="#" class="text-white">
          <i class="pi pi-twitter"></i>
        </a>
        <a href="#" class="text-white">
          <i class="pi pi-whatsapp"></i>
        </a>
        <a href="#" class="text-white">
          <i class="pi pi-instagram"></i>
        </a>
        <a href="#" class="text-white">
          <i class="pi pi-linkedin"></i>
        </a>
      </div>
    </div>

    <div class="text-center mb-6 md:mb-0">
      <h2 class="text-xl font-bold mb-2">Quick Links</h2>
      <ul>
        <li><a href="#" class="text-white">Home</a></li>
        <li><a href="#" class="text-white">About</a></li>
        <li><a href="#" class="text-white">Use Cases</a></li>
        <li><a href="#" class="text-white">Features</a></li>
        <li><a href="#" class="text-white">Pricing</a></li>
        <li><a href="#" class="text-white">Terms of Use</a></li>
      </ul>
    </div>

    <div class="text-center mb-6 md:mb-0">
      <h2 class="text-xl font-bold mb-2">Contact Us</h2>
      <p class="text-white mb-2">+234 7067 897 8974</p>
      <p class="text-white mb-2">+234 7067 897 8974</p>
      <p class="text-white mb-2">whatsell@info.com</p>
      <p class="text-white">HQ @ FUTO, Obinze</p>
    </div>

    <div class="text-center md:max-w-[400px]">
      <h2 class="text-xl font-bold mb-2">Join our WhatsApp Channel</h2>
      <p class="mb-2">Get access to up-to-date info, updates and news as regards WhatSell at the tip of your fingers!</p>
      <a href="https://www.whatsapp.com/WhatSell-Channel" class="text-white underline">
        https://www.whatsapp.com/WhatSell-Channel
      </a>
    </div>
  </div>
  <div class="text-center mt-6 opacity-55">
    <p>Copyright WhatSell 2024 All Rights Reserved</p>
  </div>
</footer>

</template>

<script>
    export default {
        name: "TheFooter",
    }
</script>

<style scoped>

</style>