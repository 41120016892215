<template>
    <div>
        <div class="border rounded-lg flex flex-col gap-3 p-8 mt-1">
            <div class="flex justify-center items-center text-gray-400 h-[300px]">
                <span>Nothing to see here...</span>
            </div>
            <button class="bg-orange-400 hover:bg-opacity-95 p-2 rounded-md w-fit px-6 text-white self-end mt-3">
                Upgrade
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UpgradeView",
        components:{

        },
        data(){
            return{

            }
        },
        created(){
            
        }
    }
</script>

<style scoped>

</style>